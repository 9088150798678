import React from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { BlogMeta } from "../../components/blogMeta"

export default function BlogPostWhatIsCopywriting() {
  return (
    <Layout>
      <SEO title="What is copywriting | Primespot Blog" />
      <main>
        <h1>What is Copywriting?</h1>
        <BlogMeta
          author="Primespot Marketing"
          published="May 20, 2020"
          tags={["Marketing"]}
        />
        <p>
          Outside of the advertising world, not many people use terms like
          copywriting. More often, you’ll hear words like writing or text.
        </p>
        <p>But when it comes to advertising, it’s always copywriting.</p>
        <p>
          Put simply, copywriting is writing that is designed to sell. Or you
          could call it a mass substitution for a salesperson.
        </p>
        <p>
          Believe it or not, <em>copywriter</em> is a job title. There are folks who do
          nothing but write copy. If you aren’t familiar with advertising at
          all, this might be somewhat of a shock to you. But it all makes sense
          once you consider just how important copywriting is to advertising.
        </p>
        <p>
          When was the last time you saw an advertisement without copy? Probably
          never. Copy is like the peanut butter and jelly in a delicious peanut
          butter and jelly sandwich. Without it, you’re left with just bread.
          Boring. Plain. Bread.
        </p>
        <p>
          Consider for a moment the most important part of any advertisement.
          No, I’m not talking about the picture.
        </p>
        <p>I’m talking about the headline.</p>
        <p>
          A poor headline will instantly break any advertisement. Consider this
          famous quote by the great advertiser, David Ogilvy.
        </p>
        <blockquote>
          “On the average, five times as many people read the headline as read
          the body copy. When you have written your headline, you have spent
          eighty cents out of your dollar.”
          <footer>David Ogilvy</footer>
        </blockquote>
        <p>
          The headline is absolutely the Atlas of an advertisement--the titan
          responsible for holding up the rest of it. The person (or people)
          responsible for writing the headline will be--you guessed it--copywriters.
        </p>
        <p>
          Copywriting extends beyond advertisements as well. The text on your
          website is copy. And as such, it should be written by a copywriter.
        </p>
        <p>
          This is a lesson that many businesses fail. Too many managers or
          business owners believe they should write their own website copy.
          After all, who knows their business better than them?
        </p>
        <p>
          To an executive who holds this opinion, I would ask if they also
          believe they should design and code their own website?
        </p>
        <p>
          Those executives who take it upon themselves to design or code their
          own websites rarely achieve professional quality results.
        </p>
        <p>
          So I suppose the crux of this post is to ask why should copywriting be
          any different?
        </p>
      </main>
    </Layout>
  )
}
